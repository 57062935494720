@mixin scrollbar($color: rgba(0, 0, 0, 0.25)) {
    &::-webkit-scrollbar {
        height: 12px;
        width: 5px;
        margin-right: 5px;
        background: transparent;

        &:horizontal {
            height: 5px;
            width: 12px;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: $color;
        -webkit-border-radius: 1ex;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    @-moz-document url-prefix() {
        scrollbar-width: thin;
    }
}
