$input-height: 2.2rem;

[ui-theme*='tiny'] {
    --default-height: 2.2rem;
    --ui-height: 2.2rem;

    .ui-button {
        --height: var(--ui-height);
        --padding: 0 1.5rem !important;
        --min-width: 5rem;
    }

    .icon-button {
        --min-width: 0px;
        --padding: 0 !important;
    }

    .input {
        --height: var(--ui-height);
        --padding: 0.7rem;

        &.textarea {
            --height: var(--ui-height);
            --padding: 0.5rem;
        }

        .input-icon {
            --margin-top: -1.85rem;
        }
    }

    .side-panel {
        --horizontal-padding: 1rem;
    }

    .selectable {
        --height: var(--ui-height);
    }

    .ui-toggle-switch {
        --height: 1rem;
        --width: 2rem;
        --switcher-width: 0.8rem;
        --switcher-height: 0.8rem;
        --toggle-border-size: 1px;
    }

    .ui-tooltip {
        --padding: 5px 10px;
    }

    .ui-notification {
        --padding: 0.9rem 3.6rem 0.9rem 1rem;
        --width: 28rem;
    }

    .ui-option {
        --checkbox-container-margin: 17px;
        --checkbox-margin: -21px;
    }

    ui-accordion-item {
        .header {
            --padding: 0.4rem 0.8rem;
        }
    }
}
