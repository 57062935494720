/* All styles required for UI to work in an app */
@import './style/overlay-prebuilt.css';
@import './style/a11y-prebuilt.css';
@import './style/deeppurple-amber.css';
@import './style/index.scss';
@import './style/directives/index.scss';

.ui-body-grabbing {
    cursor: grabbing !important;
    user-select: none;
}
