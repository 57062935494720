[ui-theme*='minimal'] {
    --default-font-size: 1.1rem;
    --default-font-weight: 400;

    --ui-font-size: 1.1rem;
    --ui-font-weight: 400;
    font-size: var(--ui-font-size);
    font-weight: var(--ui-font-weight);

    .ui-button {
        --font-size: var(--ui-font-size);
        --font-weight: 200;
        --icon-font-size: var(--ui-font-size);
    }

    .label {
        --font-size: var(--ui-font-size);
        --font-weight: var(--ui-font-weight);
    }

    .input {
        --font-size: var(--ui-font-size);
        --line-height: 1.1;
        --font-weight: var(--ui-font-weight);
    }

    .icon {
        --font-size: 1.4rem;
    }

    .side-panel {
        --font-size: var(--ui-font-size);
    }
}
