// From https://gist.github.com/jamesflorentino/2028413
// ui-easings = {
//     // Cubic
//     easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190),
//     easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000),
//     easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1.000),

//     // Circ
//     easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335),
//     easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000),
//     easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860),

//     // Expo
//     easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035),
//     easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000),
//     easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000),

//     // Quad
//     easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530),
//     easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940),
//     easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955),

//     // Quart
//     easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220),
//     easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000),
//     easeInOutQuart : cubic-bezier(0.770, 0.000, 0.175, 1.000),

//     // Quint
//     easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060),
//     easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000),
//     easeInOutQuint : cubic-bezier(0.860, 0.000, 0.070, 1.000),

//     // Sine
//     easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715),
//     easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000),
//     easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950),

//     // Back
//     easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045),
//     easeOutBack: cubic-bezier(0.175,  0.885, 0.320, 1.275),
//     easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550)
// }

html {
    --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
    --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

    --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
    --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

    --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
    --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

    --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

    --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
    --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

    --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
    --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

    --ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
    --ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
    --ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

    --ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
    --ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    --ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
