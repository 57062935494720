@import '../animation-types.scss';
@import '../config.scss';

@keyframes #{$ANIMATION_NAME_PREFIX}#{map-get($ui-animations,'zoom-back')}#{$ANIMATION_TYPE_IN} {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        opacity: 1;
        transform: scale(1.1);
    }
    80% {
        opacity: 1;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes #{$ANIMATION_NAME_PREFIX}#{map-get($ui-animations,'zoom-back')}#{$ANIMATION_TYPE_OUT} {
    0% {
        opacity: 1;
        transform: none;
    }
    20% {
        opacity: 1;
        transform: scale(0.95);
    }
    50% {
        opacity: 1;
        transform: scale(1.1);
    }
    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}
