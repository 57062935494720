.ui-draggable-indicator {
    $indicatorSize: 1.6rem;
    position: fixed;
    display: block;
    width: 35px;
    height: 30px;

    z-index: -1;
    top: 0;
    left: 0;
    pointer-events: none;
    &:before {
        content: attr(data-count);
        $indicatorSize: 1.6rem;
        position: absolute;
        display: block;
        z-index: -1;
        bottom: 0;
        right: 0;
        margin-top: 10px;
        background: var(--ui-color-primary);
        color: var(--ui-static-color-white);
        font-size: 1rem;
        text-align: center;
        border-radius: 10px;
        width: $indicatorSize;
        height: $indicatorSize;
        line-height: $indicatorSize;
        pointer-events: none;
    }
}
.ui-dragging .ui-drop-zone {
    &:after {
        content: '';
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        position: absolute;
        pointer-events: none;
        border: 2px solid var(--border-color);
        opacity: 0;
        transition: opacity 0.2s ease;
        z-index: 1;
    }
}
.ui-dragging .ui-drop-zone-over {
    &:after {
        opacity: var(--border-opacity);
    }
    > * {
        pointer-events: none;
    }
}
