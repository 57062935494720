@import '../animation-types.scss';
@import '../config.scss';

@keyframes #{$ANIMATION_NAME_PREFIX}#{map-get($ui-animations,'slide-top')}#{$ANIMATION_TYPE_IN} {
    0% {
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes #{$ANIMATION_NAME_PREFIX}#{map-get($ui-animations,'slide-top')}#{$ANIMATION_TYPE_OUT} {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-1000px);
        opacity: 0;
    }
}
