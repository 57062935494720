@import '../animation-types.scss';
@import '../config.scss';

@keyframes #{$ANIMATION_NAME_PREFIX}#{map-get($ui-animations,'slide-up')}#{$ANIMATION_TYPE_IN} {
    from,
    80%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1) !important;
    }

    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }

    35% {
        transform: translate3d(0, -10px, 0);
    }

    80% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes #{$ANIMATION_NAME_PREFIX}#{map-get($ui-animations,'slide-up')}#{$ANIMATION_TYPE_OUT} {
    0% {
        opacity: 1;
        transform: none;
    }
    20% {
        opacity: 1;
        transform: scale(0.95);
    }
    50% {
        opacity: 1;
        transform: scale(1.1);
    }
    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}
