$font-size-small: 1rem;
$font-size-normal: 1.1rem;

[ui-theme*='tiny'] {
    --default-font-size: 1.1rem;
    --default-font-weight: 400;

    --ui-font-size: 1.1rem;
    --ui-font-weight: 400;
    $font-size: var(--ui-font-size);
    font-weight: var(--ui-font-weight);

    --font-size: var(--ui-font-size);
    --font-weight: var(--ui-font-weight);

    .ui-button {
        --font-size: $font-size-small;
        --font-weight: 200;
        --icon-font-size: $font-size-small;
    }

    .input {
        --font-size: $font-size-normal;
        --line-height: 1.1 * $font-size-normal;
    }

    .icon {
        --font-size: 1.4rem;
    }

    .side-panel {
        --font-size: $font-size-normal;
    }
}
